.form-field {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
  background: #fff;
  border: 1px solid rgba($color: #000000, $alpha: 0.1);
  border-left: 3px solid #e6eaf1;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  &__label {
    font-size: 0.75rem;
    font-weight: 400;
    color: rgba($color: #000000, $alpha: 0.6);
  }

  &__input {
    width: 100%;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 0.875rem;
    color: rgba($color: #000000, $alpha: 0.4);
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
    margin-top: 14px;

    &:focus {
      outline: none;
    }
  }

  &__icon {
    position: absolute;
    bottom: 0;
    right: 0;

    &:hover {
      cursor: pointer;
    }

    > svg {
      font-size: 1rem;
    }
  }
}
