.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.login {
  &__text,
  &__link-text {
    font-size: 0.75rem;
    font-weight: 400;
  }

  &__text {
    color: rgba(0, 0, 0, 0.6);
  }

  &__link-text {
    color: #000;
  }
}
