.dashboard {
  height: calc(100vh - 14rem);
  max-width: 60rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__text {
    // font-family: 'Monument Extended', sans-serif;
    font-weight: bolder;
    line-height: 1.15;
    font-size: 5rem;

    &--paragraph {
      font-weight: bold;
      color: #673ffa;
      font-size: 1.5rem;
      margin-top: 4px;
    }
  }
}

.bounce {
  animation: bounce 2s ease infinite;
}
@keyframes bounce {
  70% {
    transform: translateX(0%);
  }
  80% {
    transform: translateX(-20%);
  }
  90% {
    transform: translateX(0%);
  }
  95% {
    transform: translateX(-7%);
  }
  97% {
    transform: translateX(0%);
  }
  99% {
    transform: translateX(-3%);
  }
  100% {
    transform: translateX(0);
  }
}
