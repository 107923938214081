@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600;700&display=swap');

.mtls-textarea {
  font-family: 'Roboto Mono', monospace;
  border: 2px solid rgba($color: #000000, $alpha: 0.1);
  border-radius: 2px;
  padding: 10px;
}

.mtls-description {
  color: #969696;
}
