.scope {
  margin-bottom: 0.5rem;

  &__text {
    font-size: 0.75rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }
}

.results-btn {
  > .arrow-right-icon {
    transform: rotateX(0);
    transition: transform 150ms ease-in-out;
  }

  &.active > .arrow-right-icon {
    transform: rotateZ(90deg);
  }
}
