.apari-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 11px;
  padding-right: 16px;

  &__wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__menu {
    position: absolute;
    top: 100%;
    right: 1rem;
    background-color: #fff;
    border-radius: 0.25rem;
    box-shadow: 0 2px 5px 0 rgba($color: #000000, $alpha: 0.1);
    opacity: 0;
    pointer-events: none;
    transform: translateY(-10px);
    transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
    overflow: hidden;
    z-index: 999;

    &--list {
      &-item {
        list-style: none;
        font-size: 0.75rem;
        font-weight: 400;
        padding: 0.5rem 1rem;
        transition: background-color 150ms ease-in-out;
        cursor: pointer;

        &:hover {
          background-color: rgba($color: #000000, $alpha: 0.1);
        }
      }
    }
  }

  &.active &__wrapper + &__menu {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }
}
