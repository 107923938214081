.top-navigation {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  padding: 15px 0;
  width: 100%;
  justify-content: flex-end;
  background: #fff;
  z-index: 99;

  &__list {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0;

    &--item {
      font-size: 0.875rem;
      font-weight: 400;
      list-style: none;
      padding: 8px 11px;
      color: #969696;
      text-transform: uppercase;
    }
  }

  &__user--dropdown {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 11px;
    padding-right: 16px;

    .dropdown-wrapper {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .user-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: #eaeef2;
      width: 50px;
      height: 50px;
      margin-right: 10px;

      &__initial {
        font-size: 1.5rem;
        font-weight: 600;
        color: #673ffa;
      }
    }

    .dropdown-menu {
      position: absolute;
      top: calc(100% + 1rem);
      right: 1rem;
      background-color: #fff;
      padding: 1.25rem;
      border-radius: 0.25rem;
      box-shadow: 0 2px 5px 0 rgba($color: #000000, $alpha: 0.1);
      opacity: 0;
      pointer-events: none;
      transform: translateY(-10px);
      transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;

      &__user {
        display: flex;
        align-items: center;

        &--details {
          display: flex;
          flex-direction: column;
        }

        &--name {
          font-size: 0.75rem;
          font-weight: 600;
          color: rgba($color: #000000, $alpha: 0.8);
          margin: 0;
          padding-bottom: 3px;
        }

        &--email {
          font-size: 0.625rem;
          font-weight: 400;
          color: rgba($color: #000000, $alpha: 0.8);
          margin: 0;
          white-space: nowrap;
        }
      }

      &__list {
        margin: 14px 0 0 0;
        padding: 0;

        &--item {
          list-style: none;
          font-size: 0.75rem;
          font-weight: 600;
          padding: 4px 0;
          cursor: pointer;
        }
      }
    }

    &.active .dropdown-wrapper + .dropdown-menu {
      opacity: 1;
      transform: translateY(0);
      pointer-events: auto;
    }

    .dropdown-arrow {
      transform: rotateX(0);
      transition: transform 150ms ease-in-out;
    }

    &.active .dropdown-arrow {
      transform: rotateZ(180deg);
    }
  }
}

.support-btn {
  background: #673ffa;
  color: #fff;
  border-radius: 2px;
}

.api-management-link {
  text-decoration: underline;
  color: rgba($color: #000000, $alpha: 0.8);
}

@media only screen and (max-width: 1280px) {
  .top-navigation {
    &__list {
      display: none;
    }
  }
}

// @media only screen and (max-width: 1024px) {
//   .top-navigation {
//     display: none;
//   }
// }
