.two-factor {
  &__paragraph {
    font-size: 13px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8);
    width: 500px;
  }

  &__text,
  &__link-text {
    font-size: 0.75rem;
    font-weight: 400;
  }

  &__text {
    color: rgba(0, 0, 0, 0.6);
  }

  &__link-text {
    color: #000;
  }
}

