.hamburger-icon {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  padding: 40px 16px;
  height: 50px;

  svg:hover {
    cursor: pointer;
  }
}

.apari__nav-heading {
  text-align: center;
  font-weight: bold;
  font-size: 1.25rem;
  color: #fff;
}

.sidebar {
  position: sticky;
  top: 0;
  left: 0;
  min-width: 300px;
  min-height: 100vh;
  background-color: #673ffa;
  z-index: 100;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 82px;
  border-bottom: 2px solid rgba($color: #000000, $alpha: 0.1);

  img {
    width: 50%;
  }
}

.sidebar-dropdown-arrow {
  transform: rotateX(0);
  transition: transform 150ms ease-in-out;
  color: #fff;

  &.active {
    transform: rotateZ(90deg);
  }
}

.environment-container {
  margin-bottom: 30px;
  height: 84px;
  width: 100%;
  border-bottom: 2px solid rgba($color: #000000, $alpha: 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.items-container {
  margin: 0 15px;

  .item-active > div {
    background: rgba($color: #000000, $alpha: 0.1);
  }

  .item {
    text-decoration: none;

    > div {
      transition: background 150ms ease-in-out;
    }

    &:hover > div {
      background: rgba($color: #000000, $alpha: 0.1);
    }
  }
}

.company-container {
  position: absolute;
  bottom: 0;
  height: 84px;
  width: 100%;
  border-top: 2px solid rgba($color: #000000, $alpha: 0.1);
  border-bottom: 2px solid rgba($color: #000000, $alpha: 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .sidebar {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    transition: transform 100ms ease-in-out;
    z-index: 999;

    &.active {
      transform: translateX(0);
      box-shadow: 2px 0 10px -2px #b2b2b2;
    }
  }
}
