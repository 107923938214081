.toggle {
  position: relative;
  display: flex;
  width: 95px;
  height: 32px;
  border-radius: 2px;
  padding: 2px;
  display: flex;
  align-items: center;
  transition: background-color 150ms ease-in-out;

  &__inner-rectangle {
    position: absolute;
    top: 2px;
    background-color: #fff;
    width: calc(50% - 2px);
    border-radius: 2px;
    height: calc(100% - 4px);
    transition: right 300ms ease-in-out;
  }

  &__text {
    position: absolute;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    width: 50%;
  }
}

.toggle-lock-icon {
  color: rgba($color: #000000, $alpha: 0.6);
  margin-left: 6px;
}
