.nav-item {
  width: 100%;
  display: flex;
  align-items: center;

  &__name {
    font-size: 1rem;
    font-weight: 400;
  }
}
