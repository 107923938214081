.apari {
  &__app-container {
    padding: 7rem 6rem;
    width: 100%;
    height: 100vh;
    overflow: scroll;
  }

  &__heading {
    font-weight: normal;
    font-size: 2rem;
    color: rgba($color: #000000, $alpha: 0.8);
  }

  &__subheading {
    font-weight: normal;
    font-size: 1.5rem;
    color: rgba($color: #000000, $alpha: 0.8);
  }

  &__regular {
    font-weight: normal;
    font-size: 1rem;
    line-height: 22px;
  }

  &__secret-text {
    &:hover {
      cursor: pointer;
      color: #673ffa;
    }
  }
}

.disable-select {
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.css-79ws1d-MuiModal-root {
  z-index: 999 !important;
}
