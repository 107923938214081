.apari__secondary-btn {
  background-color: #fff;
  width: 100%;
  padding: 8px;
  border: 2px solid #673ffa;
  border-radius: 2px;
  color: #673ffa;
  font-size: 1rem;
  font-weight: 600;
  transition: background-color 100ms ease-in-out, color 100ms ease-in-out;

  &:hover {
    background-color: #673ffa;
    color: #fff;
  }
}
